// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applications-jsx": () => import("./../../../src/pages/applications.jsx" /* webpackChunkName: "component---src-pages-applications-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-converter-jsx": () => import("./../../../src/pages/converter.jsx" /* webpackChunkName: "component---src-pages-converter-jsx" */),
  "component---src-pages-cssgame-jsx": () => import("./../../../src/pages/cssgame.jsx" /* webpackChunkName: "component---src-pages-cssgame-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nains-jsx": () => import("./../../../src/pages/nains.jsx" /* webpackChunkName: "component---src-pages-nains-jsx" */)
}

